import { useSettingsStore } from '../stores/useSettingsStore'
import { useDomainFeatures } from './useDomainFeatures'

type PublicFileKey =
  | 'publicFileMarketbook'
  | 'publicFileMarketFilterConfig'
  | 'publicFileFastBetConfig'
  | 'publicFilePrivacyPolicy'
  | 'publicFileCookiesPolicy'
  | 'publicFileTerms'
  | 'publicFileAmlPolicy'
  | 'publicFileResponsibleGambling'
  | 'publicFileSelfExclusion'
  | 'publicFileReloadBonusTerms'
  | 'publicFileCashbackTerms'
  | 'publicFileRakeBackTerms'
  | 'publicFileFreebetTerms'
  | 'publicFileFreespinsTerms'
  | 'publicFileReferralProgramTerms'
  | 'publicFileRatRaceTerms'
  | 'publicFileCasinoQuestTerms'
  | 'publicFileCasinoBetTerms'
  | 'publicFileBonusForDepositTerms'
  | 'publicFileBetTerms'
  | 'publicFileCasinoTerms'

export function usePublicFileUrl(fileKey: PublicFileKey) {
  const { settings } = storeToRefs(useSettingsStore())
  const { customDocuments } = useDomainFeatures()

  return computed(
    () => customDocuments.value?.[fileKey] ?? settings.value?.[fileKey] ?? '',
  )
}
